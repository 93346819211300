// If you don't want to use TypeScript you can delete this file!
import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { PageProps, Link, graphql, useStaticQuery } from "gatsby";
import HomePage from "../components/homepage";

const Index: React.FC<PageProps<{}>> = ({ path }) => {
  return <HomePage price="0.20" serviceCharge={25} sym="R" />;
};

export default Index;
